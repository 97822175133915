<template>
  <v-app>
    <!-- <vue-particles
      color="#240b36"
      :particleOpacity="0.7"
      :particlesNumber="50"
      shapeType="star"
      :particleSize="4"
      linesColor="#c31432"
      :linesWidth="2"
      :lineLinked="true"
      :lineOpacity="0.4"
      :linesDistance="150"
      :moveSpeed="3"
      :hoverEffect="true"
      hoverMode="grab"
      :clickEffect="true"
      clickMode="push"
    >
    </vue-particles> -->
    <v-container class="container">
      <v-card class="grid" width="95%" :elevation="11">
        <v-form ref="form" v-model="valid" :lazy-validation="lazy">
          <v-card-text class="text">Car Category</v-card-text>
          <v-select
            v-model="category"
            :items="categories"
            :rules="[(v) => !!v || 'Category is required']"
            label="Car Category"
            required
            solo
            outlined
            chips
            dense
          ></v-select>
          <v-card-text class="text">Car Make</v-card-text>
          <v-select
            v-model="make"
            :items="makes"
            :rules="[(v) => !!v || 'Make is required']"
            label="Car Make"
            required
            small-chips
            outlined
            solo
            dense
            @change="loadModels"
          ></v-select>
          <v-card-text class="text">Car Model</v-card-text>
          <v-select
            v-model="model"
            :items="models"
            :rules="[(v) => !!v || 'Model is required']"
            label="Car Model"
            required
            small-chips
            outlined
            solo
            dense
          ></v-select>
          <v-card-text class="text">Car Manufactured Year</v-card-text>
          <v-select
            v-model="year"
            :items="years"
            :rules="[(v) => !!v || 'Manufactured year is required']"
            label="Car Manufactured Year"
            required
            small-chips
            outlined
            solo
            dense
          ></v-select>
          <v-card-text class="text"
            >Car Color (eg : 'Red' , 'White')</v-card-text
          >
          <v-text-field
            v-model="color"
            :rules="globalRules"
            label="Car Color"
            required
            solo
            outlined
            dense
          ></v-text-field>
          <v-card-text class="text">Car Registration Number</v-card-text>
          <v-text-field
            v-model="plateNumber"
            :rules="globalRules"
            label="Car Number Plate"
            required
            solo
            outlined
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per Day</v-card-text>
          <v-text-field
            v-model="pricePerDay"
            :rules="globalRules"
            label="Price Per Day (RM)"
            required
            solo
            outlined
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per 3 Days</v-card-text>
          <v-text-field
            v-model="pricePer3days"
            :rules="globalRules"
            label="Price Per 3 Days (RM)"
            required
            solo
            outlined
            dense
          ></v-text-field>
          <v-card-text class="text">Car Price Per Week</v-card-text>
          <v-text-field
            v-model="pricePerWeek"
            :rules="globalRules"
            label="Price Per Week (RM)"
            required
            solo
            outlined
            dense
          ></v-text-field>

          <v-card-text class="text">Add Car Features</v-card-text>
          <v-card-text class="text">CAR TYPE</v-card-text>
          <v-select
            v-model="carFeatures[0].type"
            :items="type"
            label="Type"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">TRANSMISSION</v-card-text>
          <v-select
            v-model="carFeatures[0].transmission"
            :items="trans"
            label="Transmission"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">SEATS</v-card-text>
          <v-select
            v-model="carFeatures[0].seats"
            :items="seats"
            label="Seats"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">FUEL TYPE</v-card-text>
          <v-select
            v-model="carFeatures[0].petrol"
            :items="fuel"
            label="Fuel"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">GPS</v-card-text>
          <v-select
            v-model="carFeatures[0].gps"
            label="GPS"
            :items="bool"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">BLUETOOTH</v-card-text>
          <v-select
            v-model="carFeatures[0].bluetooth"
            :items="bool"
            label="BLUETOOTH"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">AUX</v-card-text>
          <v-select
            v-model="carFeatures[0].aux"
            :items="bool"
            label="AUX"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">USB</v-card-text>
          <v-select
            v-model="carFeatures[0].usb"
            :items="bool"
            label="USB"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-card-text class="text">SMOKING</v-card-text>
          <v-select
            v-model="carFeatures[0].smoking"
            :items="bool"
            label="SMOKING"
            required
            solo
            outlined
            dense
          ></v-select>

          <v-col :cols="12" md="4">
            <v-card-text class="text" style="color: #000"
              >Upload car images</v-card-text
            >
            <v-file-input
              style="
                          background-image: linear-gradient(to right, #ad5389, #3c1053);
                          -webkit-background-clip: text !important;
                          -webkit-text-fill-color: transparent !important; ,
                        "
              prepend-icon="mdi-image-outline"
              accept="image/*"
              @change="uploadImage"
          /></v-col>
          <div v-if="imageUrl">
            <v-row>
              <v-col
                v-for="url in imageUrl"
                :key="url.id"
                align="center"
                style="opacity: 1 !important"
              >
                <v-card class="grid" width="300px" height="300px">
                  <v-row
                    justify="center"
                    align="center"
                    style="height: 61px !important"
                  >
                    <v-btn
                      class="rounded-btn"
                      dark style="padding-bottom: 10px !important;"
                      @click="removeUrl(url)"
                    >
                      x
                    </v-btn>
                  </v-row>

                  <v-img :src="url.url" max-height="250px" max-width="250px">
                  </v-img
                ></v-card>
              </v-col>
            </v-row>
          </div>

          <v-row align="center" no-gutters>
            <v-col :cols="4" align="center">
              <v-btn :disabled="!valid" dark class="button" @click="validate">
                Add
              </v-btn>
            </v-col>
            <v-col :cols="4" align="center">
              <v-btn dark class="button" @click="reset"> Reset </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card>
    </v-container>
  </v-app>
</template>

<script>
import DataService from "../services/dataService.js";
const dataService = new DataService();

export default {
  data() {
    return {
      valid: true,
      globalRules: [(v) => !!v || "Field is required"],
      categories: [
        "SuperCar",
        "2 Door Sports Coupe",
        "Luxury Sedan",
        "Local",
        "4WD Drive Vehicles",
        "MPV & Van",
        "Premium",
        "SUV",
      ],
      list: [],
      category: {},
      makes: [],
      make: "",
      models: [],
      model: "",
      years: [],
      year: "",
      pricePerDay: "",
      pricePer3days: "",
      pricePerWeek: "",
      plateNumber: "",
      color: "",
      desc: "",
      imageUrl: [],
      lazy: false,
      carFeatures: [{
        type: "",
        seats: "",
        transmission: "",
        gps: "",
        smoking: "",
        bluetooth: "",
        petrol: "",
        aux: "",
        usb: "",
      }],
      type: ["SEDAN", "HATCHBACK", "MPV", "SUV"],
      status: ["Active", "Inactive"],
      bool: ["true", "false"],
      trans: ["Manual", "Auto"],
      fuel: ["95", "97", "99", "100", "Diesel"],
      seats: ["2", "5", "7", "12"],
    };
  },

  mounted() {
    this.getCarData();
  },

  created() {
    this.generateYears();
  },

  methods: {
    trackButtonClick(a) {
      console.log(a);
      this.$gtag.event("Button_clicks", {
        event_category: a,
        event_label: "User clicked on icon",
      });
    },
    async getCarData() {
      if (this.$store.state.user.roles[0] == "admin") {
        this.$setLoader();
        await dataService.getCarData().then((res) => {
          this.list = res.data.getCarData;
          this.getMakes();
        });

        this.$disableLoader();
      } else {
        alert("USER NOT AUTHORIZED TO VIEW THIS PAGE");
        this.$router.push({ path: `/` });
      }
    },

    async validate() {
      if (this.$refs.form.validate()) {
        this.$setLoader();
        let car = {
          category: this.category,
          make: this.make,
          model: this.model,
          year: this.year,
          pricePerDay: this.pricePerDay,
          pricePer3days: this.pricePer3days,
          pricePerWeek: this.pricePerWeek,
          plateNumber: this.plateNumber,
          color: this.color,
          desc: this.desc,
          imageUrl: this.imageUrl,
          features: this.carFeatures,
        };
        await dataService.addCar(car).then((res) => {
          console.log(res);
          window.location.reload();
        });

        this.$disableLoader();
      }
    },

    removeUrl(url) {
      const index = this.imageUrl.findIndex((item) => item.id === url.id);
      if (index !== -1) {
        this.imageUrl.splice(index, 1);
      }
    },

    generateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i > currentYear - 10; i--) {
        this.years.push(i);
      }
    },

    reset() {
      this.$refs.form.reset();
    },

    getMakes() {
      this.$setLoader();
      for (let m of this.list) {
        this.makes.push(m.make);
      }
      this.$disableLoader();
    },

    loadModels() {
      this.$setLoader();
      this.models = [];

      for (let m of this.list) {
        if (m.make == this.make) {
          // this.models.push(m.models);
          for (let c of m.models) {
            this.models.push(c);
          }
        }
      }
      this.$disableLoader();
    },
    // async getProduct() {
    //   this.$setLoader();
    //   await dataService
    //     .getProduct({ _id: this.$route.params.cardId })
    //     .then((res) => {
    //       this.product = res.data.product[0];
    //     });

    //   this.$disableLoader();
    // },

    cancelUpload() {
      if (confirm("Cancel Upload?")) {
        this.showUpload = false;
        this.preview = null;
      }
    },

    uploadImage(file) {
      if (file) {
        let maxId = 0;
        this.$setLoader();
        for (let image of this.imageUrl) {
          if (image.id > maxId) {
            maxId = image.id;
          }
        }
        let newId = maxId + 1; // Increment the maximum id for the new image
        file.resource_type = "image";
        file.url = process.env.VUE_APP_CLOUDINARY_URL_IMAGE;
        this.uploadFileToCloudinary(file).then((fileResponse) => {
          // Build the Cloudinary URL with transformation parameters
          const url = `https://res.cloudinary.com/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/image/upload/${fileResponse.public_id}.${fileResponse.format}`;

          this.imageUrl.push({ url: url, id: newId });
          this.$disableLoader();
        });
      }
    },

    uploadFileToCloudinary(file) {
      return new Promise(function (resolve, reject) {
        let formData = new FormData();
        formData.append(
          "upload_preset",
          process.env.VUE_APP_CLOUDINARY_PRESET1
        );
        formData.append("resource_type", file.resource_type);
        formData.append("file", file);
        let request = new XMLHttpRequest();
        request.open(
          "POST",
          `https://api.cloudinary.com/v1_1/${process.env.VUE_APP_CLOUDINARY_CLOUD_NAME}/upload`,
          true
        );
        request.setRequestHeader("X-Requested-With", "XMLHttpRequest");
        request.onreadystatechange = () => {
          if (request.readyState === 4 && request.status === 200) {
            let response = JSON.parse(request.responseText);
            resolve(response);
          }
          if (request.status !== 200) {
            let response = JSON.parse(request.responseText);
            let error = response.error.message;
            this.errorText = "error uploading files " + error;
            this.isError = true;
            reject(error);
          }
        };
        request.onerror = (err) => {
          alert("error: " + err);
          reject(err);
        };
        request.send(formData);
      });
    },
  },
};
</script>

<style scoped lang="css">
.container {
  /* margin-top: 5%; */
  max-width: none !important;
}

#particles-js {
  background-color: #f9ece0 !important;
  position: absolute !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 0 !important;
}
</style>
